import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import "./csid.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

// Ag
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { CSRInterface } from "./interfaces";
import { csr } from "../../dummyData";
import Swal from "sweetalert2";
import { dateTimeFormatter } from "src/util/dateTimeFormatter";
import { RenewModal } from "src/components/util/modal Renew/index.jsx"
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
]);

const CSIDComplianceGenerator = (props: any) => {
  const navigate = useNavigate();
  document.title = "ZATCA E-Invoicing By Nebras";
  const [formData, setFormData] = useState<any>("");
  const [open, setOpen] = useState<any>(false);
  const [renModal, setRenModal] = useState<any>(false);
  const [otpobj, setotp] = useState<any>({});
  const [selectedCSR, selectCSR] = useState<any>({});

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  const handleGenerateCSID = async (e: any) => {
    e.preventDefault();
    const headers = {
      OTP: formData.otp,
      ["Accept-Version"]: formData.accept_version,
    };
    try {
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/compliance/csid",
        formData,
        { headers, withCredentials: true }
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Generated",
          text: `Compliance CSID Generated `,
          icon: "success",
        });
      }
      console.log("response is here", response.data);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        Swal.fire({
          title: "Error!",
          text: `${error?.response?.data?.error}`,
          icon: "warning",
        });
      }
      console.log(error);
    }
  };

  const storeDataAndOpenToggle = async (data: any) => {
    console.log("Selected Data From Grid", data);
    setFormData({
      ...formData,
      csr: data.csr,
    });
    toggleLeftCanvas();
  };
  const DetailCellRenderer = (props: any) => {
    const data = props.data;

    const handleGeneratButton = () => {
      if (renModal) {
        alert("wait until request didn't fulfill")
        return
      }

      setRenModal((preVal: any) => !preVal)
      selectCSR(props.data)
    }

    return (
      <Row className=" detail-row">
        <Col xs={6} className="detail-col">
          <div className="detail-group">
            <div className="detail-label">ID:</div>
            <div>{data._id}</div>
          </div>
          <div className="detail-group ">
            <div className="detail-label">Binary Token:</div>
            <div className="detail-value">{data?.binaryToken}</div>
          </div>

          <div className="detail-group">
            <div className="detail-label">Secret Key:</div>
            <div>{data.secretKey}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Serial Number:</div>
            <div>{data.serialNumber}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Session:</div>
            <div>{data.session}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Status:</div>
            <div>{data.status}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Version:</div>
            <div>{data.version}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Timestamp:</div>
            <div>{data.dbtimestamp}</div>
          </div>
        </Col>
        <Col xs={4} className="text-right">
          <div className="detail-group">
            <div className="detail-label">CSR Config Name:</div>
            <div>{data.directory.CSRConfig.name}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">CSR Config Path:</div>
            <div>{data.directory.CSRConfig.path}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">CSR Name:</div>
            <div>{data.directory.CSR.name}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">CSR Path:</div>
            <div>{data.directory.CSR.path}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">PAK Name:</div>
            <div>{data.directory.PAK.name}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">PAK Path:</div>
            <div>{data.directory.PAK.path}</div>
          </div>
        </Col>
        <Col xs={2}>
         
          <br />
          <button
            style={{ minWidth: "120px" }}

            className="custom-primary-btn"
            onClick={handleGeneratButton}
          >
            Renew CSR
          </button>
        </Col>
      </Row>
    );
  };

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "500px" }),
    []
  );
  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [csr, setCSR] = useState<CSRInterface[]>([]);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "", cellRenderer: "agGroupCellRenderer", maxWidth: 60 },
    {
      field: "id",
      headerName: "S No.",
      maxWidth: 80,
      valueGetter: (params: any) => params.node.rowIndex + 1, // Display row index starting from 1
    },
    { field: "_id", hide: true },
    { field: "csr", headerName: "CSR" },
    { field: "privatekey", headerName: "Private Key" },
    { field: "serialNumber" },
    { field: "directory.CSRConfig.name", hide: true },
    { field: "directory.CSRConfig.path", hide: true },
    { field: "directory.CSR.name", hide: true },
    { field: "directory.CSR.path", hide: true },
    { field: "directory.PAK.name", hide: true },
    { field: "directory.PAK.path", hide: true },
    { field: "version" },
    { field: "dbtimestamp", valueGetter: (params: any) => dateTimeFormatter(params?.data?.dbtimestamp) },
    // { field: "status" },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
    };
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === "1");
    });
  }, []);

  const fetchCSR = async () => {
    try {
      const response = await axios.get(
        "https://api-zatca-uat.nebrascorp.com/api/csr", {
        withCredentials: true
      }
      );
      // console.log("organization response", response.data?.response);
      setCSR(response.data?.response);
      // console.log("csr from api ", response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCSR();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <RenewModal open={renModal} setOpen={setRenModal} organization={selectedCSR} otpsetter={setotp} otpvalu={otpobj} />
          <Row className="align-items-center">
            <Col md={6}>
              <h5 className="card-title">
                Total CSR (certificates)
                <span className="text-muted fw-normal ms-2">
                  {csr?.length ? csr?.length : 0}
                </span>
              </h5>
            </Col>
          </Row>

          <div style={containerStyle}>
            <div style={gridStyle} className={"ag-theme-quartz myCustomGridStyles"}>
              <AgGridReact<CSRInterface>
                rowData={csr}
                columnDefs={columnDefs}
                // defaultColDef={defaultColDef}
                masterDetail={true}
                detailCellRenderer={detailCellRenderer}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
          </div>
        </Container>
        <Offcanvas
          isOpen={open}
          toggle={toggleLeftCanvas}
          direction="end"
          style={{ minWidth: "40%" }}
        >
          <OffcanvasHeader toggle={toggleLeftCanvas}>
            ADD CSR Details
          </OffcanvasHeader>
          <OffcanvasBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Form onSubmit={handleGenerateCSID} style={{ minWidth: "80%" }}>
              {/* <FormGroup floating>
                <Input
                  type="select"
                  required
                  name="csr"
                  onChange={handleFormData}
                >
                  <option value="">Select Company </option>

                  {csr?.map((item: any, index: any) => {
                    // console.log("simple", item, "shahzaib", companyProfile[item]);
                    return (
                      <option key={index} value={item.binaryToken}>
                        {item.serialNumber}
                      </option>
                    );
                  })}
                </Input>
                <Label for="csr.organization.identifier">
                  Organization Identifier
                </Label>
              </FormGroup>{" "} */}
              <Row>
                <Col xs={6}>
                  <FormGroup floating>
                    <Input
                      type="number"
                      name="otp"
                      onChange={handleFormData}
                      id="csr.common.name"
                      required
                    ></Input>
                    <Label for="">OTP</Label>
                  </FormGroup>{" "}
                </Col>

                <Col xs={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      name="accept_version"
                      onChange={handleFormData}
                      id="csr.common.name"
                      required
                    ></Input>
                    <Label for="">Accept Version</Label>
                  </FormGroup>{" "}
                </Col>
              </Row>
              <Button className="btn btn-success">Generate CSID</Button>
              <Button className="btn btn-success">Renew CSID</Button>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default CSIDComplianceGenerator;
