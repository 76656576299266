import React from "react";

import { ConfigProvider, Skeleton } from "antd";
import { Table } from "reactstrap";

interface TableLoaderProps {
  rows: any;
  col: any;
}

const TableLoader = ({ rows, col }: TableLoaderProps) => {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorFill: "#edebebb8",

            colorFillContent: "#aaa5a570",
          },
        }}
      >
        <Table className="table mb-0">
          <thead className="table-light" >
            <tr>
              {col.map((c: any, index: any) => (
                <th key={index}>
                  <Skeleton
                    title={{ width: "100%" }}
                    active
                    paragraph={false}
                  />
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((i: any, index: any) => (
              <tr key={index}>
                {col.map((c: any, index: any) => (
                  <td key={index}>
                    <Skeleton
                      title={{ width: "100%" }}
                      active
                      // style={{backgroundColor: "grey"}}
                      paragraph={false}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ConfigProvider>
    </>
  );
};

export default TableLoader;
