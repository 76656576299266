import React, { useEffect, useState } from "react";
import nebrasLogo from "../../assets/images/nebras-logo.png";
import zatcaLogo from "../../assets/images/logo-sm-zatca.png";


//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";

// import Widgets from "./Widgets";
import { FaIndustry } from "react-icons/fa";
import { BiSolidContact } from "react-icons/bi";
import { MdCall } from "react-icons/md";
import { MdLeaderboard } from "react-icons/md";
import { IoTodaySharp } from "react-icons/io5";
import axios from "axios";
import { cA } from "@fullcalendar/core/internal-common";

const Dashboard = () => {
  const [call, setCall] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [todayCall, setTodayCall] = useState<any>([]);
  const [todayMeeting, setTodayMeeting] = useState<any>([]);
  const [qualifiedLeads, setQualifiedLeads] = useState<any>([]);
  const [salePersoneCall, setSalePersonCall] = useState<any>();
  const [salePersoneQCall, setSalePersoneQCall] = useState<any>();
  const [salePerson, setSalePerson] = useState<any>(false);
  const [salePersonQ, setSalePersonQ] = useState<any>(false);
  const [salePersonforLeads, setSalePersonforLeads] = useState<any>();

  const getCall = async () => {
    try {
      const response = await axios.post(
        `https://api-sact.nebrascorp.com/api/call/get`,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("call", response.data);
      setCall(response?.data);

      // Getting today call
      // const serverTime = response?.data?.todayDate;
      const serverTime = new Date().toISOString();
      console.log("serverTime", serverTime);
      const todaysCalls = await response?.data?.data?.filter(
        (call: any) => call.lockedOn.slice(0, 10) === serverTime.slice(0, 10)
      );

      setTodayCall(todaysCalls);
      console.log("call saved");
      //

      // Getting qualified call
      const qualifiedTypes = [
        "qualified",
        "win",
        "low",
        "highly interested",
        "medium interested",
        "low interested",
      ];
      console.log("main ", response?.data?.data);
      const allQualifiedCall = await response?.data?.data?.filter((call: any) =>
        qualifiedTypes.includes(call.declareStatus)
      );
      setQualifiedLeads(allQualifiedCall);
      console.log("all qualified leads", allQualifiedCall);
      //

      // Getting today meeting
      const todaysMeeting = await response.data.data.filter(
        (call: any) =>
          call.ClientAskedTo === "meeting" &&
          call.lockedOn.slice(0, 10) === serverTime.slice(0, 10)
      );

      setTodayMeeting(todaysMeeting);
      //
    } catch (error: any) {
      console.log("error from call", error?.response?.data?.error);
    }
  };
  const getCompany = async () => {
    try {
      const response = await axios.get(
        `https://api-sact.nebrascorp.com/api/company/get`,
        { withCredentials: true }
      );
      console.log("company", response.data);
      setCompany(response.data);
    } catch (error: any) {
      console.log("error", error?.response?.data?.error);
    }
  };
  const getContact = async () => {
    try {
      const response = await axios.get(
        `https://api-sact.nebrascorp.com/api/auth/users`,
        {
          withCredentials: true,
        }
      );
      console.log("user", response.data);
      setUsers(response.data.users);
      console.log(users);
    } catch (error: any) {
      console.log("error", error?.response?.data?.error);
    }
  };

  const handleSalePersonChangeForCalls = async (e: any) => {
    try {
      setSalePerson(e.target.value);
      console.log("call?.data", call?.data);
      const filterCallForSalePerson = await call?.data?.filter(
        (val: any) => val.lockedBy === e.target.value
      );
      setSalePersonCall(filterCallForSalePerson);
    } catch (error) {
      console.log("error from handleSalePersonChangeForCalls", error);
    }
  };

  const handleSalePersonChangeForQualifiedLeads = async (e: any) => {
    try {
      setSalePersonQ(e.target.value);
      console.log(" e.target.value", e.target.value);
      console.log(" all qualified ", qualifiedLeads);
      const filterLeadsForSalePerson = await qualifiedLeads?.filter(
        (val: any) => val.lockedBy === e.target.value
      );
      console.log("filterLeadsSalePerson", filterLeadsForSalePerson);
      setSalePersoneQCall(filterLeadsForSalePerson);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCall();
    getCompany();
    getContact();
  }, []);

  document.title = "Dashboard | ZATCA ";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" /> */}

          {/* <Row> */}
            {/* Box Start */}
            {/* <Col xl={3} md={6} key={"keydummy"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Total Companies
                      </span>
                      <h4 className="mb-3"> */}
                        {/* {widget.isDoller === true ? "$" : ""} */}

                        {/* <span className="counter-value">
                          {company.data?.length}
                        </span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <FaIndustry size={60} />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      {" "}
                      <hr className="mb-0 mt-0" style={{ maxWidth: 100 }} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl={3} md={6} key={"keydummy2"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Total Users
                      </span>
                      <h4 className="mb-3">
                        {widget.isDoller === true ? "$" : ""}

                        <span className="counter-value">{users?.length}</span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <BiSolidContact size={60} />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      {" "}
                      <hr className="mb-0 mt-0" style={{ maxWidth: 100 }} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl={3} md={6} key={"key"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Total Calls {salePerson && "of" + " " + salePerson}
                      </span>
                      <h4 className="mb-3">
                        <span className="counter-value">
                          {salePerson
                            ? salePersoneCall?.length
                            : call.data?.length}
                        </span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <MdCall size={60} />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      <Input
                        type="select"
                        name="lockedBy"
                        onChange={handleSalePersonChangeForCalls}
                      >
                        <option value="">Select sale person</option>
                        {users?.map((val: any) => (
                          <option value={val.username}>{val.username}</option>
                        ))}
                      </Input>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl={3} md={6} key={"keydummy3"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Total Qualified Leads
                      </span>
                      <h4 className="mb-3">
                        <span className="counter-value">
                          {salePersonQ
                            ? salePersoneQCall?.length
                            : qualifiedLeads?.length}
                        </span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <MdLeaderboard size={60} />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      <span className="ms-1 text-muted font-size-13">
                        <Input
                          type="select"
                          onChange={handleSalePersonChangeForQualifiedLeads}
                        >
                          <option value="">Select sale person</option>
                          {users?.map((val: any) => (
                            <option value={val.username}>{val.username}</option>
                          ))}
                        </Input>
                      </span>
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            {/* Box Ends */}
          {/* </Row> */}
          {/* <Row>
            <Col xl={3} md={6} key={"keydummy5"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Today Meeting
                      </span>
                      <h4 className="mb-3">
                         {widget.isDoller === true ? "$" : ""} 

                        <span className="counter-value">
                          {todayMeeting?.length}
                        </span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <IoTodaySharp size={60} color="#2ab57d" />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      {" "}
                      <hr className="mb-0 mt-0" style={{ maxWidth: 100 }} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col xl={3} md={6} key={"keydummy4"}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <span className="text-muted mb-3 font-size-17 lh-1 d-block text-truncate">
                        Today Calls
                      </span>
                      <h4 className="mb-3">
                        {widget.isDoller === true ? "$" : ""} 

                        <span className="counter-value">
                          {todayCall?.length}
                        </span>
                      </h4>
                    </Col>
                    <Col xs={6}>
                      <div className="d-flex justify-content-center">
                        <MdCall size={60} color="#2ab57d" />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-nowrap">
                    <span className="ms-1 text-muted font-size-13">
                      {" "}
                      <hr className="mb-0 mt-0" style={{ maxWidth: 100 }} />
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}


          <Row  >
            <Col  xs={12} className="d-flex justify-content-center align-center" style={{alignItems: "center", gap: '0.5rem'}} >
              <img src={nebrasLogo} alt="nebrasLogo" height={45} width={45}/>
              <h2 className="font-size-32" style={{textAlign: "center"}}>Welcome to NEBRAS - ZATCA E-Invoicing Solution Platform.</h2>
              <img src={zatcaLogo} alt="zatcaLogo"  height={50} width={50}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
