import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import "./index.scss";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcEmptyFilter } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";

import QRCode from "react-qr-code";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";

import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { SlOptionsVertical } from "react-icons/sl";
import { IoEye, IoPrintOutline } from "react-icons/io5";
import { Skeleton } from 'antd';
import GridLoader from "../../components/Common/GridLoader";
import { dateFormatter } from "src/util/dateFormatter";
import { dateTimeFormatter } from "src/util/dateTimeFormatter";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
  CsvExportModule,
  ExcelExportModule,
]);


const DetailCellRenderer = (props: any) => {
  const data = props.data;
  // console.log(data, "dataaaaaaaaaaaaa");
  const downloadPdfInvoice = async (ardno: string, typecode: string) => {
    try {
      console.log("ardno", ardno);
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/embed-xml",
        {
          filename: ardno,
          typecode: typecode,
        },
        {
          responseType: "blob", // important to receive the file as blob
        }
      );

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = ardno; // Set the file name you want to download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Ensure the link is removed from the DOM
      window.URL.revokeObjectURL(downloadUrl); // Release the object URL
      console.log(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  // const base64String = btoa(data?.qr);
  // if(base64String){
  // }
  // const actualBase64 = `data:image/png;base64, ${base64String}`
  // console.log(actualBase64, "base644444444")

  return (
    <Row className="detail-row ">
      <Col xs={4} className="text-right" style={{ margin: "0px 0 12px 0" }}>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Invoice No:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.oldardocumentno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Reference No:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.referenceno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Retail Store No:{" "}
            </span>
            <span>{data?.invoiceObject?.retailstoreno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Customer Name:{" "}
            </span>
            <span>{data?.invoiceObject?.customername}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Customer VAT No.:{" "}
            </span>
            <span>{data?.invoiceObject?.registrationno}</span>
          </div>

          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Address:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.address}</span>
          </div>

          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Created User:{" "}
            </span>
            <span>{data?.invoiceObject?.creuser}</span>
          </div>
        </div>
      </Col>
      <Col xs={4} className="text-right" style={{ margin: "0px 0 12px 0" }}>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Currency:{" "}
            </span>
            <span>{data?.invoiceObject?.currencyno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Payment Method:{" "}
            </span>
            <span>{data?.invoiceObject?.paymentmethodno}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Gross Doc Amount:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.grossdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Discount Amount:{" "}
            </span>{" "}
            <span>{data?.invoiceObject?.discountdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Net Doc Amount:{" "}
            </span>
            <span>{data?.invoiceObject?.netdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Tax Doc Amount:{" "}
            </span>
            <span>{data?.invoiceObject?.taxdocamount.toFixed(2)}</span>
          </div>
          <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
            <span
              style={{
                display: "inline-block",
                width: "175px",
                textAlign: "right",
                fontSize: "0.8rem",
                paddingRight: "8px",
                fontWeight: "500",
              }}
            >
              Doc Amount Including Tax:{" "}
            </span>
            <span>{data?.invoiceObject?.docamountincludingtax.toFixed(2)}</span>
          </div>
        </div>
      </Col>
      <Col xs={3} className="text-right" style={{ margin: "0px 0 12px 0" }}>
        {data?.success ? (
          <QRCode
            size={256}
            style={{ height: "175px", width: "175px", float: "right" }}
            value={data?.qr}
            viewBox={`0 0 256 256`}
          />
        ) : (
          <div>
            <h5>Response</h5>
            <p
              style={{
                overflow: "scroll",
                width: "fit-content",
                fontSize: "0.8rem",
                lineHeight: '1.2rem',
                backgroundColor: "#ff0000c0",
                padding: "16px",
                borderRadius: "08px",
                color: "white",
                fontWeight: "800",
                whiteSpace: "wrap"
              }}
            >
              {data?.response}
            </p>
          </div>
        )}
      </Col>
      {/* {
        data?.success 
        && */}
      <Col
        className="text-right"
        style={{
          margin: "0px 0 12px 0",
          position: "absolute",
          bottom: 0,
          right: 0,
          textAlign: "right",
        }}
        xs={1}
      >
        <button
          style={{

            padding: "8px",
            borderRadius: "12px",
            border: 0,

            ...!data?.success && { cursor: 'not-allowed' },
            ...!data?.success ? { background: "#72c9d22e", } : { background: "#72C9D2", },

          }}

          onClick={() => downloadPdfInvoice(data?.ardocumentno, data?.invoiceObject.typecode)}
        >
          {" "}
          <IoPrintOutline size={25} color="white" />
        </button>
      </Col>
      {/* } */}
    </Row>
  );
};


const loaderRows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const loaderCol = [1, 2, 3, 4, 5, 6, 7, 8]

const InoviceDetailsList = () => {
  const [loading, setLoading] = useState(false);
  const [fetchedInvoices, setFetchedInvoice] = useState<any>([]);
  const [reminder, setReminder] = useState<any>(false);
  const [value, setValue] = useState<any>();
  const gridRef = useRef<AgGridReact<any>>(null);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  useEffect(() => {
    // fetchReported();
    findInvoiceDetails()
  }, []);

  const FailureRenderer = (props: any) => {
    return <span style={{ color: "red" }}>Failure</span>;
  };
  const SuccessRenderer = (props: any) => {
    return <span style={{ color: "green" }}>Success</span>;
  };

  // console.log(fetchedInvoices, "clearnceData");
  const containerStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  // const [columnDefs, setColumnDefs] = useState<ColDef[]>([
  //   { field: "S No.", cellRenderer: "agGroupCellRenderer" },
  //   {
  //     field: "success",
  //     cellRenderer: (params: any) =>
  //       params.value ? <SuccessRenderer /> : <FailureRenderer />,
  //   },
  //   { field: "ardocumentno" },
  //   { field: "oldardocumentno" },
  //   { field: "transactionlayerno" },
  //   { field: "qr" },
  //   { field: "createdAt" },
  // ]);
  // const defaultColDef = useMemo<ColDef>(() => {
  //   return {
  //     flex: 1,
  //     filter: true,
  //     floatingFilter: true,
  //   };
  // }, []);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "", cellRenderer: "agGroupCellRenderer", maxWidth: 60 },

    {
      field: "id",
      headerName: "S No.",
      maxWidth: 80,
      valueGetter: (params: any) => params.node.rowIndex + 1, // Display row index starting from 1
    },
    {
      field: "ardocumentno",
      headerName: "Reported Invoice No.",
      maxWidth: 200,
    },
    { field: "oldardocumentno", headerName: "Invoice No.", maxWidth: 200 },
    { field: "transactionlayerno", headerName: "Invoice Type", maxWidth: 150 },

    {
      field: "invoiceObject.customername",
      headerName: "Customer Name",
      maxWidth: 150,
    },
    {
      field: "invoiceObject.grossdocamount",
      headerName: "Gross Total",
      maxWidth: 150,
    },

    {
      field: "invoiceObject.postdate",
      headerName: "Posting Date",
      maxWidth: 300,
      valueGetter: (params: any) => dateFormatter(params.data.invoiceObject?.postdate), // Display row index starting from 1

    },
    {
      field: "createdAt", headerName: "Submission Date", maxWidth: 300,
      valueGetter: (params: any) => dateTimeFormatter(params.data.createdAt), // Display row index starting from 1
    },
    {
      field: "success",
      headerName: "Status",
      maxWidth: 130,
      cellRenderer: (params: any) =>
        params.value ? <SuccessRenderer /> : <FailureRenderer />,
    },

    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 130,
      cellRenderer: (params: any) => (
        <>
          <IoEye style={{ marginRight: "12px" }} /> <SlOptionsVertical />
        </>
      ),
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // flex: 1,
      // maxWidth: 200,
      filter: true,
      // floatingFilter: true,
      resizable: true,
    };
  }, []);

  const onBtExport = useCallback(() => {
    gridRef?.current!.api.exportDataAsExcel();
  }, []);

  const findInvoiceDetails = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault(); // Prevent default form submission

    setLoading(true);
    try {
      // const [reporting, clearance, preinvoices] = await Promise.all([
      //   axios.get(`https://api-zatca-uat.nebrascorp.com/api/reporting/${value}`, {
      //     withCredentials: true,
      //   }),
      //   axios.get(`https://api-zatca-uat.nebrascorp.com/api/clearance/${value}`, {
        //     withCredentials: true,
        //   }),
        //   axios.get(`https://api-zatca-uat.nebrascorp.com/api/preinvoices/${value}`, {
      //     withCredentials: true,
      //   })
      // ]);

      // console.log("actual ", preinvoices.data)
      // const data1 = reporting.data?.response?.data;
      // const data2 = clearance.data?.response?.data;
      // const data3 = preinvoices.data?.response?.data;
      // console.log("data 1", data1);
      // console.log("data 2", data2);
      // console.log("data 3", data3);
      
      // const data = [...data1, ...data2, data3];
      // console.log("data all", data);
      
        const res = await axios.get(`https://api-zatca-uat.nebrascorp.com/api/preinvoices/${value}`, {
          withCredentials: true,
        })
        console.log(res.data)
let allData = [...res.data.response.data.phase1,...res.data.response.data.phase2]
    console.log("actuall all ",allData)

      setFetchedInvoice(allData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    console.log("submitted");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    findInvoiceDetails(e);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div
          className="page-title-box d-sm-flex align-items-center justify-content-between"
          style={{ paddingBottom: 0 }}
        >
          <h2 className="mb-0 font-size-24">
            Invoice Details{" "}
            <span className="font-size-12" style={{ display: "block" }}>

            </span>
          </h2>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <a href="/Invoices">Invoices</a>
              </li>
              <li className="active breadcrumb-item" aria-current="page">
                <span>Invoice Details</span>
              </li>
            </ol>
          </div>
        </div>

        <Row>
          <Col lg="12">


            <div className="row">
              <Form onSubmit={handleSubmit}>
                <FormGroup className="col-md-6 position-relative">
                  <Label for="myInput">Search Invoice</Label>

                  <div>

                    <Input
                      onChange={(e) => setValue(e.target.value)}
                      type="text"
                      id="myInput"
                      style={{ paddingRight: '40px', maxWidth: '400px' }} // Adjust padding to make space for the icon
                      placeholder="Search by ardocument no"
                    />
                    <button type="submit" className="search-button">
                      <i className="fas fa-search search-icon"></i>
                    </button>
                  </div>
                </FormGroup>
              </Form>
            </div>

            <div>
              {loading ? (
                <div className="d-flex  align-items-center" style={{ padding: "32px 0" }}><GridLoader rows={loaderRows} col={loaderCol} /></div>

              ) : fetchedInvoices?.length > 0 ? (
                <div style={containerStyle}>
                  <div style={{ textAlign: "right" }}>
                    <button
                      onClick={onBtExport}
                      style={{
                        margin: "5px 0",
                        fontWeight: "500",
                        border: "0",
                        background: "#72C9D2",
                        borderRadius: "8px",
                        padding: "4px 12px",
                        color: "white",
                      }}
                    >
                      Export to Excel
                    </button>
                  </div>

                  <div
                    style={gridStyle}
                    className={"ag-theme-quartz myCustomGridStyles"}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={fetchedInvoices}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      pagination
                      paginationPageSize={500}
                      paginationPageSizeSelector={false}
                      masterDetail={true}
                      detailRowAutoHeight={true}
                      detailCellRenderer={detailCellRenderer}
                      // onFirstDataRendered={onFirstDataRendered}
                      defaultExcelExportParams={{
                        fileName: "reporting_invoices",
                      }}

                    // onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <Col>
                    <Row>
                      <FcEmptyFilter size={35} />
                      <h3 className="text-muted text-center">
                        No Data
                      </h3>
                    </Row>
                  </Col>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default InoviceDetailsList;
