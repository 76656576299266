import React, { useCallback, useMemo, useRef, useState, StrictMode, useEffect } from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, FirstDataRenderedEvent, ModuleRegistry } from "@ag-grid-community/core";
import { Col, Container, Row, } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcEmptyFilter } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { IoEye } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";
import GridLoader from "../../components/Common/GridLoader";


import { CsvExportModule } from "@ag-grid-community/csv-export";

import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { Skeleton } from "antd";
import { dateTimeFormatter } from "src/util/dateTimeFormatter";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
  CsvExportModule,

  ExcelExportModule,
]);

const DetailCellRenderer = (props: any) => {
  const data = props.data;
  console.log(data, 'preinvoicesdataaaaaaaa')
  return (
    <Row className="detail-row ">
    <Col xs={4} className="text-right" style={{margin: "0px 0 12px 0"}}>
      <div
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Invoice No:{" "}
          </span>{" "}
          <span>{data?.transactionNo}</span>
        </div>
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Reference No:{" "}
          </span>{" "}
          <span>{data?.obj?.referenceno}</span>
        </div>
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Retail Store No:{" "}
          </span>
          <span>{data?.obj?.retailstoreno}</span>
        </div>
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Customer Name:{" "}
          </span>
          <span>{data?.obj?.customername}</span>
        </div>
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Customer VAT No.:{" "}
          </span>
          <span>{data?.obj?.registrationno}</span>
        </div>
       
        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Address:{" "}
          </span>{" "}
          <span>{data?.obj?.address}</span>
        </div>

        <div style={{ margin: "8px 16px", flexBasis: "49%" }}>
          <span
            style={{
              display: "inline-block",
              width: "175px",
              textAlign: "right",
              fontSize: "0.8rem",
              paddingRight: "8px",
              fontWeight: "500",
            }}
          >
            Created User:{" "}
          </span>
          <span>{data?.obj?.creuser}</span>
        </div>
      </div>
    </Col>
    <Col xs={4} className="text-right" style={{margin: "0px 0 12px 0"}}>
    </Col>

    <Col  xs={3} className="text-right" style={{margin: "0px 0 12px 0", }}>
            <img src={data?.response} alt="qrCode" height="175px" width="175px" style={{float: "right"}}/>
            
    </Col>
    </Row>
  );
};



const loaderRows=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
const loaderCol=[1,2,3,4,5,6,7,8]

const PreInvoicesList = () => {
  const [loading, setLoading] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);
  const [reminder, setReminder] = useState<any>(false);
  const gridRef = useRef<AgGridReact<any>>(null);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const fetchPreInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api-zatca-uat.nebrascorp.com/api/preinvoices`,{
          withCredentials:  true
        }
      );
      console.log("PreInvoices respons", response?.data);
      setInvoicesData(response.data.response.data.phase1);
      

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPreInvoices();
  }, []);

  const FailureRenderer = (props: any) => {
    return (
      <span style={{ color: 'red' }}>Failure</span>
    );
  };
  const SuccessRenderer = (props: any) => {
    return (
      <span style={{ color: 'green' }}>Success</span>
    );
  };


  console.log(invoicesData, "PreInvoicesList")


  const containerStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  // const [columnDefs, setColumnDefs] = useState<ColDef[]>([
  //   { field: "S No.", cellRenderer: "agGroupCellRenderer" },
  //   // {
  //   //   field: "success",
  //   //   cellRenderer: (params: any) => params.value ? <SuccessRenderer />
  //   //     : <FailureRenderer />,
  //   // },
  //   { field: "processCode", },
  //   { field: "transactionNo", },
  //   { field: "refNo" },
  //   { field: "transactionLayer", },
  //   { field: "vatNumber", },
  //   // { field: "createdAt" },
  // ]);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "", cellRenderer: "agGroupCellRenderer", maxWidth: 60 },

    {
      field: "id",
      headerName: "S No.",
      maxWidth: 80,
      valueGetter: (params: any) => params.node.rowIndex + 1, // Display row index starting from 1
    },
    {
      field: "processCode",
      headerName: "Process Code",
      maxWidth: 200,
    },
    { field: "transactionNo", headerName: "Invoice No.", maxWidth: 200 },
    { field: "transactionLayer", headerName: "Invoice Type", maxWidth: 150 },
    { field: "refNo", headerName: "Reference No", maxWidth: 150 },

    {
      field: "obj.customername",
      headerName: "Customer Name",
      maxWidth: 400,
    },

    { field: "obj.taxdocamount", headerName: "Tax Amount", maxWidth: 150 },

    {
      field: "obj.grossdocamount",
      headerName: "Amount Including Tax",
      maxWidth: 150,
    },
    {
      field: "dbtimestamp", headerName: "Submission Date", maxWidth: 250,
      valueGetter: (params: any) => dateTimeFormatter(params.data.dbtimestamp),

    },

    // {
    //   field: "",
    //   headerName: " ",
    //   maxWidth: 300,
    // },
    // { field: "createdAt", headerName: "Submission Date", maxWidth: 300 },



  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // flex: 1,
      filter: true,
      // floatingFilter: true,
      resizable: true
    };
  }, []);

  const onBtExport = useCallback(() => {
    gridRef?.current!.api.exportDataAsExcel();
  }, []);



  return (
    <React.Fragment>
      <Container fluid>

        {/* <Row className="align-items-center">
          <Col md={6}> */}
            {/* <h5 className="card-title">
              Total Invoices
              <span className="text-muted fw-normal ms-2">
                {invoicesData?.length ? invoicesData?.length : 0}
              </span>
            </h5> */}

            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h2 className="mb-0 font-size-24">Pre Invoices <span className='font-size-12' style={{display: "block"}}>These are the simplified invoices generated through this system</span></h2>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="/Invoices">Invoices</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    <span>Pre Invoices</span>
                  </li>
                </ol>
              </div>
            </div>
          {/* </Col>


        </Row> */}

        <Row>
          <Col lg="12">
            <div>
              {
                loading
                  ?
                  (
                    <div className="d-flex  align-items-center" style={{padding: "32px 0"}}><GridLoader  rows={loaderRows} col={loaderCol}/></div>

                  )
                  :
                  invoicesData?.length > 0
                    ?
                    (
                      <div style={containerStyle} >
                        <div style={{textAlign: "right"}}>
                          <button onClick={onBtExport} style={{ margin: "5px 0" ,fontWeight: "500" , border :"0", background: "#72C9D2", borderRadius: "8px", padding: "4px 12px", color: "white"}}>Export to Excel</button>
                        </div>
                        <div style={gridStyle} className={"ag-theme-quartz myCustomGridStyles"}>
                          <AgGridReact
                      ref={gridRef}

                            rowData={invoicesData}
                            columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      // enableAdvancedFilter
                      pagination
                      paginationPageSize={500}
                      paginationPageSizeSelector={false}
                      masterDetail={true}
                      detailRowAutoHeight={true}
                      detailCellRenderer={detailCellRenderer}
                      // onFirstDataRendered={onFirstDataRendered}
                      defaultExcelExportParams={{fileName: 'pre_invoices'}}

                          />
                        </div>
                      </div>
                    )
                    :
                    (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "500px" }}
                      >
                        <Col>
                          <Row>
                            <FcEmptyFilter size={35} />
                            <h3 className="text-muted text-center">
                              No Invoices Found
                            </h3>
                          </Row>
                        </Col>
                      </div>
                    )
              }
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PreInvoicesList;
