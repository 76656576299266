import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

import withRouter from "../../../src/components/Common/withRouter";
const SidebarContent = (props: any) => {
  const currentUser = props?.screens;
  const admin = props?.admin;
  const ref: any = useRef();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li>
              <Link to="/dashboard" className="">
                <FeatherIcon icon="clipboard" />{" "}
                <span>{props.t("Dashboard")}</span>
              </Link>


            </li>


            <li className="menu-title">{props.t("TAX")} </li>

            {/* Invoices */}
            <li>
              <Link to="/#" className="has-arrow">
                <FeatherIcon icon="file-text" />{" "}
                <span>{props.t("Invoices")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/preinvoices">{props.t("Pre Invoices")}</Link>
                </li>
                <li>
                  <Link to="/clearance">{props.t("Clearance")}</Link>
                </li>
                <li>
                  <Link to="/reporting">{props.t("Reporting")}</Link>
                </li>
                <li>
                  <Link to="/invoice-details">
                    {props.t("Invoice Details")}
                  </Link>
                </li>
              </ul>
            </li>


            <li className="menu-title">{props.t("MISCELLANEOUS")} </li>

            {/* Configurations */}
            <li>
              <Link to="/#" className="has-arrow">
                <FeatherIcon icon="settings" />{" "}
                <span>{props.t("Configuration")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/onboarding-credentials">
                    {props.t("Onboarding Credentials")}
                  </Link>
                </li>
                <li>
                  <Link to="/compliance-csid">
                    {props.t("Compliance CSID")}
                  </Link>
                </li>
                <li>
                  <Link to="/certificates">{props.t("CSR (certificates)")}</Link>
                </li>


                {/* Item With sub menue  (Just for help if needed in future)*/}
                {/* <li>
                  <Link to="/#" className="has-arrow">
                    <FeatherIcon icon="layers" />{" "}
                    <span>{props.t("CSID Generator")}</span>
                  </Link>

                  <ul className="sub-menu">
                    <li>
                      <Link to="/csid-compliance-generator">
                        {props.t("Compliance")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/csid-onboarding-generator">
                        {props.t("Onboarding")}
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </li>

            {/* Settings */}
            <li>
              <Link to="/#" className="has-arrow">
                <FeatherIcon icon="layers" /> <span>{props.t("Setting")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/companyprofile">{props.t("Company Profile")}</Link>
                </li>

                <li>
                  <Link to="/data-dictionary">
                    {props.t("Data Dictionary")}
                  </Link>
                </li>

               
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
