import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Calls
import TeleSales from "src/pages/TeleSales";
import TelesalesDetails from "src/pages/TeleSales/TelesalesDetails";

//Contacts
// import ContactsGrid from 'src/pages/Contacts/contactsGrid'
import ContactsList from "src/pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "src/pages/Contacts/ContactsProfile/contacts-profile";

//Utility
import PageStarter from "src/pages/Utility/PageStarter";
import PageMaintenance from "src/pages/Utility/PageMaintenance";
import PageTimeline from "src/pages/Utility/PageTimeline";
import PageFaqs from "src/pages/Utility/PageFAQs";
import PagePricing from "src/pages/Utility/PagePricing";
import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";

// Layout

import VerticalLayout from "../components/VerticalLayout";
import HorizontalLayout from "../components/HorizontalLayout/index";

//AuthenticationInner pages
import PageLogin from "src/pages/AuthenticationInner/PageLogin";
import PageRegister from "src/pages/AuthenticationInner/PageRegister";
import RecoverPassword from "src/pages/AuthenticationInner/RecoverPassword";
import LockScreen from "src/pages/AuthenticationInner/LockScreen";
import ConfirmMail from "src/pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "src/pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "src/pages/AuthenticationInner/TwoStepVerfication";

//Authentication pages
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import UserProfile from "src/pages/Authentication/user-profile";
import PagesComingsoon from "src/pages/Utility/PageComingsoon";
import AuthLogout from "../pages/AuthenticationInner/Logout";

//Maps====-
import ResetPassword from "src/pages/Authentication/Reset-password";
// import CompanyProfile from "src/pages/CompanyProfile/companyProfile";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import Clearance from "src/pages/Clearance";
import Reporting from "src/pages/Reporting/index";
import CSRCertificate from "src/pages/CSRCertificate";
import CSIDCompliance from "src/pages/CSIDCompliance";
import CSIDOnBoarding from "src/pages/CSIDOnBoarding";
import UploadDocument from "src/pages/UploadDocument";
// import CSRGenerator from "src/pages/CSRGenerator";
import CSRGenerator from "src/pages/CSRGenerator";
import CSIDComplianceGenerator from "src/pages/CSIDComplianceGenerator";
import CSIDOnboardingGenerator from "src/pages/CSIDOnboardingGenerator";
import CompanyProfile from "src/pages/CompanyProfile";
import PreInvoices from "src/pages/PreInvoices";
import OnboardingCredentials from "src/pages/OnboardingCredentials";
import InoviceDetails from "src/pages/invoiceDetails";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}
interface User {
  id: number;
  // other user properties
  screens: string[]; // array of accessible screens
}

export const hasAccessToScreen = (user: any, screen: string) => {
  // console.log('usersdssdasddas', user)
  return user?.admin === true || user?.screens?.includes(screen);
};

const Routing = () => {
  const userDatad = useSelector((state: any) => state?.login?.user?.userData);
  const [userData, setUserData] = useState<any>();
  const dispatch = useDispatch();

  const selectCalendar = createSelector(
    (state: any) => state.Layout,
    (state) => ({
      layoutType: state.layoutType,
    })
  );
  const [reload, setReload] = useState<any>(false);

  const { layoutType } = useSelector(selectCalendar);
  const Layout: any = getLayout(userData);

  function getLayout(user: any) {
    let layoutCls: any = VerticalLayout;
    let userData: any;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        userData = user;
        break;
    }
    return layoutCls;
  }

  const authRoutes: Array<RouteProps> = [
    //Authentication pages
    // { path: '/login', component: <Login /> },
    { path: "/logout", component: <Logout /> },
    { path: "/register", component: <Register /> },
    { path: "/recoverpw", component: <ForgetPassword /> },

    //AuthenticationInner pages
    { path: "/page-login", component: <PageLogin /> },
    { path: "/page-register", component: <PageRegister /> },
    { path: "/reset-password", component: <ResetPassword /> },
    { path: "/page-lock-screen", component: <LockScreen /> },
    { path: "/page-confirm-mail", component: <ConfirmMail /> },
    { path: "/page-email-verification", component: <EmailVerification /> },
    { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
    { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
    { path: "/page-logout", component: <AuthLogout /> },

    //utility page
    { path: "/pages-comingsoon", component: <PagesComingsoon /> },
    { path: "/pages-maintenance", component: <PageMaintenance /> },
    { path: "/pages-404", component: <Error404 /> },
    { path: "/pages-500", component: <Error500 /> },
  ];

  return (
    <Routes>
      {/*Invoices*/}

      <Route
        path="/dashboard"
        element={
          <Layout user={userDatad}>
            <Dashboard />
          </Layout>
        }
      />

      <Route
        path="/preinvoices"
        element={
          <Layout>
            <PreInvoices />
          </Layout>
        }
      />
      <Route
        path="/clearance"
        element={
          <Layout user={userDatad}>
            <Clearance />
          </Layout>
        }
      />
      <Route
        path="/reporting"
        element={
          <Layout>
            <Reporting />
          </Layout>
        }
      />
      <Route
        path="/invoice-details"
        element={
          <Layout>
            <InoviceDetails />
          </Layout>
        }
      />


      {/* " Configuration"  */}
      <Route
        path="/onboarding-credentials"
        element={
          <Layout>
            <OnboardingCredentials />
          </Layout>
        }
      />
      <Route
        path="/compliance-csid"
        element={
          <Layout>
            <CSIDOnboardingGenerator />
          </Layout>
        }
      />
      <Route
        path="/certificates"
        element={
          <Layout>
            <CSIDComplianceGenerator />
          </Layout>
        }
      />
      <Route
        path="/csr-generator"
        element={
          <Layout>
            <CSRGenerator />
          </Layout>
        }
      />
      <Route
        path="/csid-compliance-generator"
        element={
          <Layout>
            {/* <h1>Page Not Found</h1> */}
          </Layout>
        }
      />
      {/* <Route
        path="/csid-onboarding-generator"
        element={
          <Layout>
          </Layout>
        }
      /> */}

      {/* Settings */}
      <Route
        path="/companyprofile"
        element={<Layout user={userDatad}>{<CompanyProfile />}</Layout>}
      />

      {/*///////////////// Below routes is not in use //////////////////// */}

      <Route
        path="/csr"
        element={
          <Layout user={userDatad}>
            <CSRCertificate />
          </Layout>
        }
      />

      <Route
        path="/csidcompliance"
        element={
          <Layout user={userDatad}>
            <CSIDCompliance />
          </Layout>
        }
      />

      <Route
        path="/csidonboarding"
        element={
          <Layout user={userDatad}>
            <CSIDOnBoarding />
          </Layout>
        }
      />

      <Route
        path="/uploaddata"
        element={
          <Layout user={userDatad}>
            <UploadDocument />
          </Layout>
        }
      />

      {/* //////////////////////////////ZATCAS Routes Ends Here //////////////////////////////// */}



      {/* <Route
        path="/contacts"
        element={
          <Layout user={userDatad}>
            <ContactsList />
          </Layout>
        }
      />
      <Route
        path="/contacts/:id"
        element={
          <Layout user={userDatad}>
            <ContactsProfile />
          </Layout>
        }
      />
      <Route
        path="/companies"
        element={
          <Layout user={userDatad}>
            <CompaniesList />
          </Layout>
        }
      />
      <Route
        path="/country"
        element={
          <Layout user={userDatad}>
            <CountryList />
          </Layout>
        }
      />
      <Route
        path="/city"
        element={
          <Layout user={userDatad}>
            <CityList />
          </Layout>
        }
      />
      <Route
        path="/industries"
        element={
          <Layout user={userDatad}>
            <Industries />
          </Layout>
        }
      />
      <Route
        path="/users"
        element={
          <Layout user={userDatad}>
            <Users />
          </Layout>
        }
      />
      <Route
        path="/users/:id"
        element={
          <Layout user={userDatad}>
            <UserDetails />
          </Layout>
        }
      />
      <Route
        path="/entities"
        element={
          <Layout user={userDatad}>
            <LegalEntities />
          </Layout>
        }
      />
      <Route
        path="/levels"
        element={
          <Layout user={userDatad}>
            <Levels />
          </Layout>
        }
      />
      <Route
        path="/groups"
        element={
          <Layout user={userDatad}>
            <Groups />
          </Layout>
        }
      />


      <Route
        path="/contacts-profile"
        element={
          <Layout user={userDatad}>
            <ContactsProfile />
          </Layout>
        }
      /> */}
      <Route path="/logout" element={<Logout />} />

      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default Routing;
