const dateTimeFormatter = (dateTimeString: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObject = new Date(dateTimeString);
  const day = dateObject.getDate();
  const month = months[dateObject.getMonth()];
  const year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  let ampm = "AM";

  // Convert hours to 12-hour format and set AM/PM
  if (hours > 12) {
    hours -= 12;
    ampm = "PM";
  }

  // Ensure hours and minutes are displayed with leading zeros
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Use template literals to format the string
  const formattedDateTime = `${day} ${month} ${year} at ${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedDateTime;
};

// Example usage
const dateTimeString = "2024-01-11T14:40:47.060Z"; // 2:40 PM
const formattedDateTime = dateTimeFormatter(dateTimeString);
console.log(formattedDateTime); // Output: "11 January 2024 at 02:40 PM"

export { dateTimeFormatter };
