import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
// import common data
import OnboardingCredentialsList from "./list";

const OnboardingCredentials = (search: any) => {
  document.title = "Onboarding Credentials (Production) | ZATCA by Nebras";

  return (
    <React.Fragment>
      <div className="page-content px-0" >

        <Container fluid>
          <OnboardingCredentialsList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OnboardingCredentials;
