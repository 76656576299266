const styles = {
    wrapper: {
      position: "fixed", // Ensures the modal stays on top and over the entire page
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 99999, // High enough to be above all other elements
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional backdrop dimming
    },
    main: {
      position: "relative",
      minWidth: "500px",
      padding: "2rem 3rem",
      background: "#fff",
      borderRadius: "16px",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      zIndex: 1001,
    },
  };
  
  export default styles