import React, { useEffect, useState } from 'react'
import styles from "./index.js"
import { MdCancel } from "react-icons/md";
import axios from 'axios';
import loadingImage from '../../../assets/images/loading.png';

export const Modal = ({ open, setOpen, organization, otpsetter, otpvalu }) => {
    const [credLoading, setCredLoading] = useState(false);
    const [errorMessage, setResMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [responseData, setResponseData] = useState(null);


    console.log("open", open)


    const generateCert = async () => {

        if (otpvalu["otp-compliance"]?.length != 6 || otpvalu["otp-onboarding"]?.length != 6) {
            alert("Fill both fields with at least 6 characters");
            return;
        }


        console.log(organization)
        setCredLoading(true)
        const body =
        {
            "csr.common.name": `${organization.shortname}-${organization.crn}-${organization.organizationIdentifier}`,
            "csr.serial.number": `1-${organization.shortname}|2-${organization.shortname}|3-${organization.uid}`,
            "csr.organization.identifier": organization.organizationIdentifier,
            "csr.organization.unit.name": organization.tin,
            "csr.organization.name": organization.name,
            "csr.country.name": organization.countryName,
            "csr.invoice.type": organization.invoiceType,
            "csr.location.address": organization.address,
            "csr.industry.business.category": organization.industry
        }
        const header = { ...otpvalu, "accept-version": "V2" }
        console.log("body", body)
        console.log("header", header)

        try {

            const response = await axios.post("https://api-zatca-uat.nebrascorp.com/api/generate-credential",
                body, {
                withCredentials: true,
                headers: header
            })

            console.log(response.data)
            setCredLoading(false)

            setResponseData(response.data);
            setSuccessMessage("Certificate generated successfully!");

        } catch (error) {
            setCredLoading(false)
            console.log(error)
            if (error.response.data.message) {
                setResMessage(error.response.data.error)
                // setCredModal(true)
            }
        }

    }



    useEffect(() => {
        setSuccessMessage()
    }, [open])



    if (credLoading) {

        return (
            <div style={styles.wrapper}>

                <div style={styles.main}>
                    {/* <h4 style={{fontFamily:""}}>Please Wait..</h4> */}
                    <div
                        className='  d-flex justify-content-center flex-column center align-items-center '>
                        <img src={loadingImage} alt="" width={200} height={200} />
                        <br />
                        <span style={{ fontFamily: "monospace" }}>
                            Please wait while Generating certificate for {organization.name}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    // return open &&
    //     (

    //         <div style={styles.main}
    //         >

    //             <div className='d-flex justify-content-between'>
    //                 <h4>Enter OTP</h4>
    //                 <MdCancel style={{ cursor: "pointer" }} size={24} onClick={() => setOpen(false)} />

    //             </div>
    //             <br />
    //             <p>Please Enter OTP from fatoora portal to generate credentials. <br /> To get this otp you need to login with your zatca account </p>

    //             <p className=' text-danger'>{errorMessage}</p>


    //             <label htmlFor="otp-compliance"> OTP Compliance
    //                 <input id='otp-compliance' className='form-control ' name='otp-compliance' style={{ minWidth: "250px" }} type="text" onChange={(e) => otpsetter({ ...otpvalu, [e.target.name]: e.target.value })} />
    //             </label>
    //             <br />
    //             <label htmlFor="otp-onboarding"> OTP Onboarding
    //                 <input id='otp-onboarding' className='form-control' name='otp-onboarding' style={{ minWidth: "250px" }} type="text" onChange={(e) => otpsetter({ ...otpvalu, [e.target.name]: e.target.value })} />
    //             </label>
    //             <br />
    //             <div style={{ display: "flex", justifyContent: 'flex-end' }}>

    //                 <button className='custom-primary-btn' disabled={credLoading} onClick={generateCert}>
    //                     Generate
    //                 </button>
    //             </div>
    //         </div>
    //     )
    return open && (
        <div style={styles.wrapper}>
            <div style={styles.main}>
                <div className='d-flex justify-content-between'>
                    <h4>{successMessage ? "Success" : "Enter OTP"}</h4>
                    <MdCancel style={{ cursor: "pointer" }} size={24} onClick={() => setOpen(false)} />
                </div>
                <br />

                {!successMessage && (
                    <>
                        <p>Please Enter OTP from fatoora portal to generate credentials. <br /> To get this OTP you need to log in with your ZATCA account.</p>

                        {/* Show error message if any */}
                        <p className='text-danger'>{errorMessage}</p>

                        {/* OTP Fields */}
                        <label htmlFor="otp-compliance">OTP Compliance
                            <input id='otp-compliance' className='form-control' name='otp-compliance' style={{ minWidth: "250px" }} type="text" onChange={(e) => otpsetter({ ...otpvalu, [e.target.name]: e.target.value })} />
                        </label>
                        <br />
                        <label htmlFor="otp-onboarding">OTP Onboarding
                            <input id='otp-onboarding' className='form-control' name='otp-onboarding' style={{ minWidth: "250px" }} type="text" onChange={(e) => otpsetter({ ...otpvalu, [e.target.name]: e.target.value })} />
                        </label>
                        <br />

                        {/* Generate Button */}
                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                            <button className='custom-primary-btn' disabled={credLoading} onClick={generateCert}>
                                Generate
                            </button>
                        </div>
                    </>
                )}

                {/* Show success message and download button after successful response */}
                {successMessage && (
                    <>
                        <p className='text-success'>{successMessage}</p>
                        <div style={{ marginTop: "20px", display: "flex", justifyContent: 'flex-end' }}>
                            <button className='custom-primary-btn' onClick={() => {
                                const blob = new Blob([JSON.stringify(responseData)], { type: "application/json" });
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(blob);
                                link.download = "certificate.json";
                                link.click();
                            }}>
                                Download Certificate
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );


}
