const dateFormatter = (dateString: string) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    // Use template literals to format the string
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};

// Example usage
const dateString = '2024-01-11T14:40:47.060Z';
const formattedDate = dateFormatter(dateString);
console.log(formattedDate); // Output: "11 January 2024"

const dateOnlyString = '2024-01-11';
const formattedDateOnly = dateFormatter(dateOnlyString);
console.log(formattedDateOnly); // Output: "11 January 2024"

export { dateFormatter };
