import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcEmptyFilter } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { Button } from "antd";
import { dateTimeFormatter } from "src/util/dateTimeFormatter";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
]);

const downloadCredential = async () => {
  console.log("clicked");

  try {
      const response = await axios.get(
          `https://api-zatca-uat.nebrascorp.com/api/onboarding-files`,
          {
              responseType: 'blob', // Important: this tells axios to handle the response as a binary file (blob)
              withCredentials: true 
          }
      );

      console.log("certificate files response", response);

      // Create a link element, use it to trigger a download, then remove it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificates.zip'); // Name of the file to be downloaded
      document.body.appendChild(link);
      link.click();
      link.remove();
  } catch (error) {
      console.log(error);
  }
}
const DetailCellRenderer = (props: any) => {
  const data = props.data;

  return (
    <>
      <Row className="bg-dark detail-row" style={{ userSelect: "text", padding: "10px" }}>
        <Col xs={12} className="text-right">
          <div className="detail-group">
            <div className="detail-label text-light p-10">Response Details:</div>
            <pre style={{ whiteSpace: "pre", lineHeight:"1rem" }} className="text-light">
              {JSON.stringify(data?.response, null, 2)}
            </pre>
          </div>
        </Col>
      </Row>
    </>
  );
};

const OnboardingCredentialsList = () => {
  const [loading, setLoading] = useState(false);
  const [onboardingData, setOnboardingData] = useState([]);
  const [reminder, setReminder] = useState<any>(false);

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const fetchCertificates = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `https://api-zatca-uat.nebrascorp.com/api/credential-onboarding`, {
        withCredentials: true
      }
      );
      console.log("clerance", response?.data);
      setOnboardingData(response.data.response);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCertificates();
  }, []);


  const FailureRenderer = (props: any) => {
    return <span style={{ color: "red" }}>Failure</span>;
  };
  const SuccessRenderer = (props: any) => {
    return <span style={{ color: "green" }}>Success</span>;
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "", cellRenderer: "agGroupCellRenderer", maxWidth: 60 },

    {
      field: "id",
      headerName: "S No.",
      maxWidth: 80,
      valueGetter: (params: any) => params.node.rowIndex + 1, // Display row index starting from 1
    },
    
    { field: "organizationname" },
    { field: "organizationIdentifier" },
    {
      field: "success",
      cellRenderer: (params: any) =>
        params.value ? <SuccessRenderer /> : <FailureRenderer />,
    },
    { field: "dbtimestamp", headerName: "Created On",valueGetter: (params: any) => dateTimeFormatter(params?.data?.dbtimestamp)  },
    { field: "validity", valueGetter: (params: any) => dateTimeFormatter(params?.data?.validity) },
  ]);
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <h5 className="card-title">
              Total Production CSID
              <span className="text-muted fw-normal ms-2">
                {onboardingData?.length ? onboardingData?.length : 0}
              </span>
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <FaSpinner
                    // type="Puff"
                    size={35}
                    color="#5156BE"
                    height={500}
                    width={500}
                    style={{
                      animation: "rotate360 2s linear infinite",
                    }}
                  />
                </div>
              ) : onboardingData?.length > 0 ? (
                <div style={containerStyle}>
                  <div style={gridStyle} className={"ag-theme-quartz myCustomGridStyles"}>
                    <AgGridReact
                      rowData={onboardingData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      enableAdvancedFilter={true}
                      pagination
                      detailRowAutoHeight={true}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                    // Keep cell selection enabled for interaction

                    // onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <Col>
                    <Row>
                      <FcEmptyFilter size={35} />
                      <h3 className="text-muted text-center">
                        No Certificates Found
                      </h3>
                    </Row>
                  </Col>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default OnboardingCredentialsList;
