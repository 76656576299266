import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import Breadcrumbs from "../../components/Common/Breadcrumb";

// import

import axios from "axios";

const CSIDOnBoarding = (props: any) => {
  const [formData, setFormData] = useState<any>("");
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(false);

  const navigate = useNavigate();
  document.title = "ZATCA E-Invoicing By Nebras";
  // Inside your component

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };

  const handleGenerateCSID = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const headers = {
      "Accept-Version": formData.accept_version,
    };

    console.log("sending this one on 'body'  ", formData.compliance_request_id);
    try {
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/onboarding/csid",
        { compliance_request_id: formData.compliance_request_id },
        {
          headers,
          auth: {
            username: formData.username,
            password: formData.password,
          },
        }
      );
      setLoading(false);

      console.log("response.data");
      console.log(response.data?.data);
      setData(response.data?.data);
    } catch (error: any) {
      setData({});
      setLoading(false);
      if (error?.response?.status === 403) {
        // console.log(error?.response);
        // Swal.fire({
        //   title: "Already Exist!",
        //   text: `${error?.response?.data?.message}`,
        //   icon: "warning",
        // });
      }
      console.log(error);
    }
  };

  // useEffect(() => {
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="CSR"
            breadcrumbItem="Generate Onboarding CSID & Private Key"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <div className="mb-2">
                          {/* <img src={logo} alt="" height="24" /> */}
                          <span className="logo-txt">
                            Enter CRID & Credential
                          </span>
                          <br />
                          <span
                            style={{
                              marginLeft: "5px",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            The request id & authentication is must
                            required&nbsp;
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="mb-4">
                          <Link to="/clearance">
                            <FeatherIcon
                              icon="x-circle"
                              className="text-muted hover-red"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />

                  <Form onSubmit={handleGenerateCSID}>
                    <Row>
                      {/* col1 */}
                      <Col sm="6">
                        <div className="border-bottom   p-2">
                          <span>Enter Compliance Request ID</span>
                          <Input
                            type="textarea"
                            rows={6}
                            name="compliance_request_id"
                            onChange={handleFormData}
                            required
                          ></Input>
                          <br />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="border-bottom   p-2">
                          <span>Username</span>
                          <Input
                            type="text"
                            name="username"
                            onChange={handleFormData}
                            required
                          ></Input>
                          <br />
                        </div>
                        <div className="border-bottom   p-2">
                          <span>Password</span>
                          <Input
                            type="text"
                            name="password"
                            onChange={handleFormData}
                            required
                          ></Input>
                          <br />
                        </div>
                        <Row>
                          <Col sm="6">
                            <div className="border-bottom   p-2">
                              <span>Accept Versoion</span>
                              <Input
                                type="text"
                                placeholder="V1, V2"
                                name="accept_version"
                                onChange={handleFormData}
                                required
                              ></Input>
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="3"></Col>
                    </Row>
                    <hr />
                    {!loading ? (
                      <>
                        {data.binarySecurityToken && (
                          <Row>
                            <Col sm="6">
                              <div>
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  <span className="font-size-17">
                                    Generated Token
                                  </span>
                                </Label>{" "}
                                <div
                                  className="form-control"
                                  style={{
                                    width: "90%",
                                    marginTop: "12px",
                                    minHeight: "200px",
                                  }}
                                  placeholder="Enter updated outcome..."
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.binarySecurityToken}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col sm="6">
                              <div>
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <span className="font-size-17">
                                    Secret Key
                                  </span>
                                </Label>

                                <br />
                                {/* Seceret Key */}
                                <div
                                  placeholder="Enter updated remarks..."
                                  style={{
                                    width: "90%",
                                    marginTop: "6px",
                                  }}
                                  className="form-control min-height-200"
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.secret}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "1.5rem",
                                }}
                              >
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <span className="font-size-17">
                                    Request ID
                                  </span>
                                </Label>

                                <br />
                                {/* Seceret Key */}
                                <div
                                  placeholder="Enter updated remarks..."
                                  style={{
                                    width: "90%",
                                    marginTop: "6px",
                                  }}
                                  className="form-control min-height-200"
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.requestID}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginTop: "1.5rem",
                                }}
                              >
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <span className="font-size-17">
                                    Disposition Message
                                  </span>
                                </Label>

                                <br />
                                {/* Seceret Key */}
                                <div
                                  placeholder="Enter updated remarks..."
                                  style={{
                                    width: "90%",
                                    marginTop: "6px",
                                  }}
                                  className="form-control min-height-200"
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.dispositionMessage}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col style={{ minHeight: "200px" }} sm="12">
                            <div className=" w-100   align-center text-center">
                              <Spinner
                                color="primary"
                                style={{
                                  margin: "auto",
                                  height: "3rem",
                                  width: "3rem",
                                }}
                                type="grow"
                              ></Spinner>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <span className="text-muted  font-size-10">
                          {data?.error}
                        </span>
                      </Col>
                    </Row>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Button type="submit" className="btn btn-success">
                          Generate Now
                        </Button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CSIDOnBoarding;
